import axios from "@/api/config/interceptor";
import { host } from "@/api/config/host";
import { _paramsToQueryString } from "@/utils/utils";

//获取司机信息
export const getDriverInfo = (params = {}) => {
  return new Promise((resolve, reject) => {
    axios.post(`${host}/api/Driver/DriversInfoList`, params).then((res) => {
      if (res.code == 0) {
        resolve(res.data);
      } else {
        reject();
      }
    });
  });
};

//删除司机信息
export const delDriverInfo = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(
        `${host}/api/Driver/DeteDriversInfo?${_paramsToQueryString(params)}`
      )
      .then((res) => {
        if (res.code == 0) {
          resolve(res.data);
        } else {
          reject(res);
        }
      });
  });
};

//导出Excel
export const exportExcel = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${host}/api/File/ExportExcel?${_paramsToQueryString(params)}`)
      .then((res) => {
        if (res.code == 0) {
          resolve(res.data);
        } else {
          reject(res);
        }
      });
  });
};

export const TCDriverInfo = (params = {}) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${host}/api/Driver/TCDriverInfo?${_paramsToQueryString(params)}`)
      .then((res) => {
        if (res.code == 0) {
          resolve(res.data);
        } else {
          reject();
        }
      });
  });
};

//通过身份证查人
export const TCBaseInfo = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${host}/api/Applet/Driver/TCBaseInfo?${_paramsToQueryString(params)}`
      )
      .then((res) => {
        if (res.code == 0) {
          resolve(res.data);
        } else {
          reject(res);
        }
      });
  });
};

//通过身份证识别发证机关
export const GetAuthorityByIdCard = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${host}/api/v2/Driver/GetAuthority?${_paramsToQueryString(params)}`)
      .then((res) => {
        if (res.code == 0) {
          resolve(res.data);
        } else {
          reject(res);
        }
      });
  });
};

//查询合作伙伴
export const GetPartnerDrivers = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${host}/api/v2/Driver/GetPartnerDrivers`, params)
      .then((res) => {
        if (res.code == 0) {
          resolve(res.data);
        } else {
          reject(res);
        }
      });
  });
};

//下载合作伙伴
export const DownPartnerDrivers = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${host}/api/v2/Driver/DownPartnerDrivers`, params)
      .then((res) => {
        if (res.code == 0) {
          resolve(res.data);
        } else {
          reject(res);
        }
      });
  });
};

//平台编辑保存司机
export const PutDriver = (params) => {
  return new Promise((resolve, reject) => {
    axios.put(`${host}/api/v2/Driver/PutDriver`, params).then((res) => {
      if (res.code == 0) {
        resolve(res.data);
      } else {
        reject(res);
      }
    });
  });
};

//查询全部司机
export const GetDriverAll = (params) => {
  return new Promise((resolve, reject) => {
    axios.post(`${host}/api/v2/Driver/GetDriverAll`, params).then((res) => {
      if (res.code == 0) {
        resolve(res.data);
      } else {
        reject(res);
      }
    });
  });
};

// 上传司机完税凭证列表
export const putDriverTaxTable = (params) => {
  return new Promise((resolve, reject) => {
    axios.post(`${host}/api/v2/TaxForEnterprise/Import`, params).then((res) => {
      if (res.code == 0) {
        resolve(res.data);
      } else {
        reject(res);
      }
    });
  });
};

// 查询司机完税凭证列表
export const getDriverTaxPage = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${host}/api/v2/TaxForEnterprise/GetByDriver`, params)
      .then((res) => {
        if (res.code == 0) {
          resolve(res.data);
        } else {
          reject(res);
        }
      });
  });
};

// 司机单独上传完税凭证
export const putDriverTaxAdd = (params) => {
  return new Promise((resolve, reject) => {
    axios.post(`${host}/api/v2/TaxForEnterprise/Add`, params).then((res) => {
      if (res.code == 0) {
        resolve(res.data);
      } else {
        reject(res);
      }
    });
  });
};

// 导入司机列表
export const putDriverListTable = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${host}/api/v2/Driver/BatchReferenceDriver`, params)
      .then((res) => {
        if (res.code == 0) {
          resolve(res.data);
        } else {
          reject(res);
        }
      });
  });
};
