import axios from "@/api/config/interceptor";
import { host } from "@/api/config/host";
import { _paramsToQueryString } from "@/utils/utils";

//获取车辆信息
export const getVehicleInfo = (params = {}) => {
  return new Promise((resolve, reject) => {
    axios.post(`${host}/api/Vehicle/VehiclesInfoList`, params).then((res) => {
      if (res.code == 0) {
        resolve(res.data);
      } else {
        reject();
      }
    });
  });
};

//新增车辆信息
export const addVehicleInfo = (params = {}) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${host}/api/Vehicle/SaveVehicleInfoList`, params)
      .then((res) => {
        if (res.code == 0) {
          resolve(res.data);
        } else {
          reject();
        }
      });
  });
};

//删除车辆信息
export const delVehicleInfo = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(
        `${host}/api/Vehicle/DeteVehiclesInfo?${_paramsToQueryString(params)}`
      )
      .then((res) => {
        if (res.code == 0) {
          resolve(res.data);
        } else {
          reject(res);
        }
      });
  });
};

//导出Excel
export const exportExcel = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${host}/api/File/ExportExcel?${_paramsToQueryString(params)}`)
      .then((res) => {
        if (res.code == 0) {
          resolve(res.data);
        } else {
          reject(res);
        }
      });
  });
};

// 根据车辆ID查询车辆信息
export const VehicleDeatils = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${host}/api/Vehicle/VehicleDeatils?${_paramsToQueryString(params)}`)
      .then((res) => {
        if (res.code == 0) {
          resolve(res.data);
        } else {
          reject(res);
        }
      });
  });
};

// 根据车牌查询运力库车辆信息
export const GetTCVehicleInfo = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${host}/api/Vehicle/GetTCVehicleInfo?${_paramsToQueryString(params)}`
      )
      .then((res) => {
        if (res.code == 0) {
          resolve(res.data);
        } else {
          reject(res);
        }
      });
  });
};

//修改车辆信息
export const UpdateVehicleInfo = (params = {}) => {
  return new Promise((resolve, reject) => {
    axios.post(`${host}/api/Vehicle/UpdateVehicleInfo`, params).then((res) => {
      if (res.code == 0) {
        resolve(res.data);
      } else {
        reject();
      }
    });
  });
};

// 新增车辆
export const SaveVehicleInfo = (params = {}) => {
  return new Promise((resolve, reject) => {
    axios.post(`${host}/api/Vehicle/SaveVehicleInfo`, params).then((res) => {
      if (res.code == 0) {
        resolve(res.data);
      } else {
        reject();
      }
    });
  });
};

// 批量新增
export const saveDraftVehicle = (params = {}) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${host}/api/Vehicle/SaveDraftVehicleList`, params)
      .then((res) => {
        if (res.code == 0) {
          resolve(res.data);
        } else {
          reject();
        }
      });
  });
};

export const TCCarAutoCheckList = (params = {}) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${host}/api/v2/TCCarAutoCheck/TCCarAutoCheckList`, params)
      .then((res) => {
        if (res.code == 0) {
          resolve(res.data);
        } else {
          reject();
        }
      });
  });
};

//通过车牌号查询车辆信息
export const tCBaseInfo = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${host}/api/Applet/Car/TCBaseInfo?${_paramsToQueryString(params)}`)
      .then((res) => {
        if (res.code == 0) {
          resolve(res.data);
        } else {
          reject(res);
        }
      });
  });
};

//通过车牌号查询发证机关
export const GetAuthorityByCarNumber = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${host}/api/v2/Car/GetAuthority?${_paramsToQueryString(params)}`)
      .then((res) => {
        if (res.code == 0) {
          resolve(res.data);
        } else {
          reject(res);
        }
      });
  });
};

// 删除草稿箱车辆
export const DeleteDraft = () => {
  return new Promise((resolve, reject) => {
    axios.delete(`${host}/api/v2/Car/DeleteDraft`).then((res) => {
      if (res.code == 0) {
        resolve(res.data);
      } else {
        reject(res);
      }
    });
  });
};

//平台编辑保存车辆
export const UpdateVehiciInfo = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .put(`${host}/api/Vehicle/UpdateVehiciInfo/UpdateVehiciInfo`, params)
      .then((res) => {
        if (res.code == 0) {
          resolve(res.data);
        } else {
          reject(res);
        }
      });
  });
};

// 提交资料前校验资料
export const CheckCarToList = (params = {}) => {
  return new Promise((resolve, reject) => {
    axios.post(`${host}/api/Vehicle/CheckCarToList`, params).then((res) => {
      if (res.code == 0) {
        resolve(res.data);
      } else {
        reject();
      }
    });
  });
};

// 导入车辆列表
export const putVehicleListTable = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${host}/api/v2/Driver/BatchReferenceCar`, params)
      .then((res) => {
        if (res.code == 0) {
          resolve(res.data);
        } else {
          reject(res);
        }
      });
  });
};
